import { useFormik } from "formik"

import * as Yup from "yup"
import React, { useMemo, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Table,
} from "reactstrap"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import CustomSelect from "components/Common/SelectBoxes/CustomSelect"
import { isEmpty, isObject } from "lodash"
import { useDispatch } from "react-redux"
import { getProviderItems } from "store/provider-dashboard/provider-dashboard-action"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { isArray } from "lodash"
import { getSelectValueWithDefault } from "utils/providerOutUtils"
import { checkForMinusNumber, numberIsSame, removeDocument } from "utils/utils"
import ReceiptsSubmitedList from "./SubmitedList"
import SAlert from "components/Alert"

const ProviderInForm = ({
  onSubmit,
  loading,
  formMode,
  error,
  warehosues,
  providers,
  categories,
  items,
  receipt,
  projects,
}) => {
  const [item, setItem] = useState(
    receipt ? items.find(it => it.id === receipt.item_id) : null
  )

  const [selectedFiles, setselectedFiles] = useState({})
  const [submited, SetSubmited] = useState([])
  const exipreDateRef = useRef(null)
  const submissionDateRef = useRef(null)
  const [lostQtyError, setLostQtyError] = useState(null)

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files[0])
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const dispatch = useDispatch()
  const ReceiptValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      warehouse_id: receipt ? receipt?.warehouse_id : "",
      project_id: receipt ? receipt?.project_id : "",
      provider_id: receipt ? receipt?.provider_id : "",
      item_category_id: receipt ? receipt?.item_category_id : "",
      item_id: receipt ? receipt?.item_id : "",
      unit_uom_quantity_per_unfpa: receipt
        ? receipt?.unit_uom_quantity_per_unfpa
        : "",
      unit_umo_quantity_per_ip: receipt
        ? receipt?.unit_umo_quantity_per_ip
        : "",
      unit_price_per_unfpa: receipt ? receipt?.unit_price_per_unfpa : "",
      unit_price_per_ip: receipt ? receipt?.unit_price_per_ip : "",
      batch_number: receipt ? receipt?.batch_number : "",
      expire_date: receipt ? receipt?.expire_date : "",
      in_qty_per_unfpa: receipt ? receipt?.in_qty_per_unfpa : "",
      in_qty_per_ip: receipt ? receipt?.in_qty_per_ip : "",
      lost_quantity: receipt ? receipt?.lost_quantity : 0,
      lost_comment: receipt ? receipt?.lost_comment : "",
      total_value: receipt ? receipt?.total_value : "",
      date: receipt ? receipt?.date : "",
    },
    validationSchema: Yup.object().shape({
      item_category_id:
        formMode === "Add"
          ? Yup.object().required("item category is required").nullable()
          : "",
      item_id:
        formMode === "Add"
          ? Yup.object().required("item is required").nullable()
          : "",
      unit_price_per_unfpa: Yup.number().required(
        "UNFPA unit price is required"
      ),
      unit_price_per_ip: Yup.string().required(
        "unit price is required is required"
      ),
      in_qty_per_unfpa: Yup.number().required(
        "This in quantity UNFPA is required"
      ),
      in_qty_per_ip: Yup.number().required("This in quantity IP is required"),
      total_value: Yup.number().required("This in total value is required"),
      date: Yup.string().required("This in submission is required"),
    }),

    onSubmit: values => {
      values.item1 = ""
      values.item2 = ""
      values.item3 = values.item_id.label
      values.item4 = values.batch_number
      values.item5 = values.in_qty_per_ip

      const oldProject = values.project_id
      const oldWarehouse = values.warehouse_id
      const oldProvider = values.provider_id
      values.warehouse_id = getSelectValueWithDefault(
        values.warehouse_id,
        warehosues
      )

      values.provider_id = getSelectValueWithDefault(
        values.provider_id,
        providers
      )

      const olditem = values.item_id
      const oldItemCategory = values.item_category_id
      values.item_category_id = getSelectValueWithDefault(
        values.item_category_id,
        categories
      )
      values.item_id = getSelectValueWithDefault(values.item_id, filteredItems)

      values.project_id = getSelectValueWithDefault(values.project_id, projects)
      if (formMode === "Add") {
        QuantityWithLostQuantity(values)
        SetSubmited(prev => {
          return [...prev, values]
        })
        resetAllForm({
          item: olditem,
          category: oldItemCategory,
          provider_id: oldProvider,
          project_id: oldProject,
          warehouse_id: oldWarehouse,
        })
      } else {
        if (receipt?.in_qty_per_ip !== values?.in_qty_per_ip) {
          QuantityWithLostQuantity(values)
        }
        if (receipt?.lost_quantity !== values?.lost_quantity) {
          values.in_qty_per_ip = receipt?.in_qty_per_ip + receipt?.lost_quantity
          QuantityWithLostQuantity(values)
        }
        onSubmit(values, selectedFiles)
      }
    },
  })

  function QuantityWithLostQuantity(values) {
    let quantity_per_ip = values.in_qty_per_ip - values.lost_quantity
    let unfpa_quantity
    if (values.unit_uom_quantity_per_unfpa != values.unit_umo_quantity_per_ip) {
      unfpa_quantity = Math.round(
        quantity_per_ip / parseInt(values.unit_uom_quantity_per_unfpa)
      )
    } else {
      unfpa_quantity = quantity_per_ip
    }
    const total_value = parseFloat(values.unit_price_per_unfpa) * unfpa_quantity
    values.in_qty_per_ip = quantity_per_ip
    values.in_qty_per_unfpa = unfpa_quantity
    values.total_value = Math.round(total_value)
  }

  const saveAllHandler = () => {
    if (submited.length > 0) {
      onSubmit(submited, selectedFiles)
    } else {
      SAlert({ text: "No data submited", status: "info" })
    }
  }

  const resetAllForm = ({
    item,
    category,
    provider_id,
    project_id,
    warehouse_id,
  }) => {
    // ReceiptValidation.resetForm()
    // setItem(null)
    exipreDateRef.current.flatpickr.clear()
    ReceiptValidation.setFieldValue("unit_price_per_unfpa", "")
    ReceiptValidation.setFieldValue("unit_price_per_ip", "")
    ReceiptValidation.setFieldValue("batch_number", "")
    ReceiptValidation.setFieldValue("in_qty_per_ip", "")
    ReceiptValidation.setFieldValue("in_qty_per_unfpa", "")
    ReceiptValidation.setFieldValue("lost_comment", "")
    ReceiptValidation.setFieldValue("lost_quantity", "")
    ReceiptValidation.setFieldValue("total_value", "")
    ReceiptValidation.setFieldValue("expire_date", "")
    ReceiptValidation.setFieldValue("item_category_id", category)
    ReceiptValidation.setFieldValue("item_id", item)
    ReceiptValidation.setFieldValue("project_id", project_id)
    ReceiptValidation.setFieldValue("warehouse_id", warehouse_id)
    ReceiptValidation.setFieldValue("provider_id", provider_id)
    // submissionDateRef.current.flatpickr.clear()
  }

  const resetFormAfterItemChanged = () => {
    ReceiptValidation.setFieldValue("unit_price_per_unfpa", "")
    ReceiptValidation.setFieldValue("unit_price_per_ip", "")
    ReceiptValidation.setFieldValue("batch_number", "")
    ReceiptValidation.setFieldValue("in_qty_per_ip", "")
    ReceiptValidation.setFieldValue("in_qty_per_unfpa", "")
    ReceiptValidation.setFieldValue("lost_comment", "")
    ReceiptValidation.setFieldValue("lost_quantity", "")
    ReceiptValidation.setFieldValue("total_value", "")
  }

  const onWarehouseChangeHander = value => {
    ReceiptValidation.setFieldValue("warehouse_id", value)
  }
  const onProjectChangeHandler = value => {
    ReceiptValidation.setFieldValue("project_id", value)
  }

  const onProviderChangeHandler = value => {
    ReceiptValidation.setFieldValue("provider_id", value)
  }

  const onItemCategoryChangeHandler = value => {
    ReceiptValidation.setFieldValue("item_category_id", value)
    dispatch(getProviderItems(value.value))
    ReceiptValidation.setFieldValue("item_id", null)
    setItem(null)
  }

  const onItemChangeHandler = value => {
    resetFormAfterItemChanged()
    ReceiptValidation.setFieldValue("item_id", value)
    const data = items.find(item => item.id === value.value)
    setItem(data)
    ReceiptValidation.setFieldValue(
      "unit_uom_quantity_per_unfpa",
      data.unfpa_uom_quantity
    )
    ReceiptValidation.setFieldValue(
      "unit_umo_quantity_per_ip",
      data.ip_uom_quantity
    )
  }

  const onUNFPAKeyUp = value => {
    if (checkForMinusNumber(value)) {
      ReceiptValidation.setFieldValue("unit_price_per_unfpa", 0)
      return
    }
    const unfpa_quantity = parseFloat(
      ReceiptValidation.values.unit_uom_quantity_per_unfpa
    )
    const ip_quantity = parseFloat(
      ReceiptValidation.values.unit_umo_quantity_per_ip
    )

    const unfpa_price = parseFloat(
      ReceiptValidation.values.unit_price_per_unfpa
    )
    if (!numberIsSame(ip_quantity, unfpa_quantity)) {
      const price_per_ip = unfpa_price / unfpa_quantity
      ReceiptValidation.setFieldValue(
        "unit_price_per_ip",
        price_per_ip.toFixed(2)
      )
    } else {
      ReceiptValidation.setFieldValue("unit_price_per_ip", unfpa_price)
    }
  }

  const disableField = ({ isformMode }) => {
    return (
      receipt?.countDistribution > 0 || (isformMode ? formMode === "Edit" : "")
    )
  }

  const onUNFPAQuantityChange = e => {
    if (checkForMinusNumber(e)) {
      ReceiptValidation.setFieldValue("in_qty_per_unfpa", 0)
      return
    }
    let quantity_per_ip
    if (
      ReceiptValidation.values.unit_uom_quantity_per_unfpa !=
      ReceiptValidation.values.unit_umo_quantity_per_ip
    ) {
      quantity_per_ip =
        parseInt(ReceiptValidation.values.in_qty_per_unfpa) *
        parseInt(ReceiptValidation.values.unit_uom_quantity_per_unfpa)
    } else {
      quantity_per_ip = ReceiptValidation.values.in_qty_per_unfpa
    }
    const total_value =
      parseFloat(ReceiptValidation.values.unit_price_per_unfpa) *
      parseFloat(ReceiptValidation.values.in_qty_per_unfpa)
    ReceiptValidation.setFieldValue("in_qty_per_ip", quantity_per_ip)
    ReceiptValidation.setFieldValue("total_value", total_value)
  }

  const filteredItems = useMemo(
    () =>
      items.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items, resetAllForm]
  )

  return (
    <Card>
      <CardBody>
        <CustomHeaderTitle
          loading={loading}
          title={formMode === "Edit" ? "Edit Receipt" : "Add Receipt"}
          lgColumn={4}
          rowCount={false}
          orderingList={false}
          searchBox={false}
          rightBtn={true}
          rightBtnTitle={"Back"}
          rightBtnRoute={"/provider-receipts"}
        />

        <Form
          onSubmit={e => {
            e.preventDefault()
            ReceiptValidation.handleSubmit()
            return false
          }}
        >
          <Dropzone
            onDrop={acceptedFiles => {
              handleAcceptedFiles(acceptedFiles)
            }}
            useFsAccessApi={false}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div className="dz-message needsclick mt-2" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews mt-3" id="file-previews">
            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete file">
              <div className="p-2">
                <Row className="align-items-center">
                  <Col>
                    <Link to="#" className="text-muted font-weight-bold">
                      {selectedFiles.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{selectedFiles.formattedSize}</strong>
                    </p>
                    {!isEmpty(selectedFiles) && (
                      <Button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          removeDocument(setselectedFiles)
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </Card>
            {error?.document?.[0] && (
              <p className="text-danger">{error?.document?.[0]}</p>
            )}
          </div>

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Projects</Label>
                {projects && (
                  <CustomSelect
                    name="warehouse_id"
                    options={projects}
                    isDisabled={disableField({})}
                    value={
                      isObject(ReceiptValidation.values.project_id)
                        ? ReceiptValidation.values.project_id
                        : projects.find(
                            pro =>
                              ReceiptValidation.values.project_id === pro.value
                          ) || projects[0]
                    }
                    onChange={onProjectChangeHandler}
                  />
                )}
                {error?.project_id?.[0] && (
                  <p className="text-danger p-1">{error?.project_id?.[0]}</p>
                )}
              </Col>
            </Row>
          </div>

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Warehouses</Label>
                {warehosues && (
                  <CustomSelect
                    name="warehouse_id"
                    options={warehosues}
                    isDisabled={disableField({})}
                    value={
                      isObject(ReceiptValidation.values.warehouse_id)
                        ? ReceiptValidation.values.warehouse_id
                        : warehosues.find(
                            ware =>
                              ReceiptValidation.values.warehouse_id ===
                              ware.value
                          ) || warehosues[0]
                    }
                    onChange={onWarehouseChangeHander}
                  />
                )}
                {error?.warehouse_id?.[0] && (
                  <p className="text-danger p-1">{error?.warehouse_id?.[0]}</p>
                )}
              </Col>
              <Col>
                <Label className="form-label">Providers</Label>
                {providers && (
                  <CustomSelect
                    name="warehouse_id"
                    options={providers}
                    isDisabled={disableField({})}
                    value={
                      isObject(ReceiptValidation.values.provider_id)
                        ? ReceiptValidation.values.provider_id
                        : providers.find(
                            prov =>
                              ReceiptValidation.values.provider_id ===
                              prov.value
                          ) || providers[0]
                    }
                    onChange={onProviderChangeHandler}
                  />
                )}
                {error?.provider_id?.[0] && (
                  <p className="text-danger p-1">{error?.provider_id?.[0]}</p>
                )}
              </Col>
            </Row>
          </div>

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Item Categories</Label>
                {categories && (
                  <CustomSelect
                    name="item_category_id"
                    options={categories}
                    isDisabled={disableField({ isformMode: true })}
                    value={
                      isObject(ReceiptValidation.values.item_category_id)
                        ? ReceiptValidation.values.item_category_id
                        : categories.filter(
                            cate =>
                              ReceiptValidation.values.item_category_id ===
                              cate.value
                          )
                    }
                    onChange={onItemCategoryChangeHandler}
                  />
                )}
                {(ReceiptValidation.touched.item_category_id &&
                  ReceiptValidation.errors.item_category_id) ||
                error?.item_category_id ? (
                  <p className="text-danger" style={{ fontSize: "80%" }}>
                    {ReceiptValidation.errors.item_category_id ||
                      error?.item_category_id}
                  </p>
                ) : null}
              </Col>
              <Col>
                <Label className="form-label">Items</Label>
                {filteredItems && (
                  <CustomSelect
                    name="item_id"
                    options={filteredItems}
                    isDisabled={disableField({ isformMode: true })}
                    value={
                      isObject(ReceiptValidation.values.item_id)
                        ? ReceiptValidation.values.item_id
                        : filteredItems.find(
                            item =>
                              ReceiptValidation.values.item_id === item.value
                          )
                    }
                    onChange={onItemChangeHandler}
                  />
                )}
                {(ReceiptValidation.touched.item_id &&
                  ReceiptValidation.errors.item_id) ||
                error?.item_id ? (
                  <p className="text-danger" style={{ fontSize: "80%" }}>
                    {ReceiptValidation.errors.item_id || error?.item_id}
                  </p>
                ) : null}
              </Col>
            </Row>
          </div>

          {item && (
            <div className="mb-3">
              <Table>
                <thead>
                  <tr>
                    <th>UNFPA ITEM ID</th>
                    <th>IP-ITEM DESCRIPTION</th>
                    <th>UNFPA UOM</th>
                    <th>IP UNIT UOM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item?.unfp_item_name}</td>
                    <td>{item?.ip_item_description}</td>
                    <td>
                      {item?.uom}{" "}
                      {item?.unfpa_uom_quantity > 1 && item?.unfpa_uom_quantity}
                    </td>
                    <td>
                      {item?.ip_unit_umo}{" "}
                      {item?.ip_uom_quantity > 1 && item?.ip_uom_quantity}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">
                  Type Unit Price ( as per UNFPA )
                </Label>
                <Input
                  name="unit_price_per_unfpa"
                  placeholder=" Type Unit Price ( as per UNFPA )"
                  type="number"
                  onWheel={e => e.target.blur()}
                  onChange={ReceiptValidation.handleChange}
                  disabled={disableField({})}
                  onBlur={ReceiptValidation.handleBlur}
                  value={ReceiptValidation.values.unit_price_per_unfpa}
                  onKeyUp={onUNFPAKeyUp}
                  invalid={
                    (ReceiptValidation.touched.unit_price_per_unfpa &&
                      ReceiptValidation.errors.unit_price_per_unfpa) ||
                    error?.unit_price_per_unfpa?.[0]
                      ? true
                      : false
                  }
                />
                {(ReceiptValidation.touched.unit_price_per_unfpa &&
                  ReceiptValidation.errors.unit_price_per_unfpa) ||
                error?.unit_price_per_unfpa?.[0] ? (
                  <FormFeedback type="invalid">
                    {ReceiptValidation.errors.unit_price_per_unfpa ||
                      error?.unit_price_per_unfpa?.[0]}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label className="form-label">
                  Type Unit Price ( as per IP )
                </Label>
                <Input
                  name="unit_price_per_ip"
                  placeholder=" Type Unit Price ( as per IP )"
                  type="number"
                  onWheel={e => e.target.blur()}
                  onChange={ReceiptValidation.handleChange}
                  onBlur={ReceiptValidation.handleBlur}
                  value={ReceiptValidation.values.unit_price_per_ip || ""}
                  invalid={
                    (ReceiptValidation.touched.unit_price_per_ip &&
                      ReceiptValidation.errors.unit_price_per_ip) ||
                    error?.unit_price_per_ip?.[0]
                      ? true
                      : false
                  }
                  readOnly={true}
                />
                {(ReceiptValidation.touched.unit_price_per_ip &&
                  ReceiptValidation.errors.unit_price_per_ip) ||
                error?.unit_price_per_ip?.[0] ? (
                  <FormFeedback type="invalid">
                    {ReceiptValidation.errors.unit_price_per_ip ||
                      error?.unit_price_per_ip?.[0]}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Batch Number</Label>
                <Input
                  name="batch_number"
                  placeholder=" Batch Number"
                  type="text"
                  disabled={disableField({})}
                  onChange={ReceiptValidation.handleChange}
                  onBlur={ReceiptValidation.handleBlur}
                  value={ReceiptValidation.values.batch_number || ""}
                  invalid={
                    (ReceiptValidation.touched.batch_number &&
                      ReceiptValidation.errors.batch_number) ||
                    error?.batch_number?.[0]
                      ? true
                      : false
                  }
                />
                {(ReceiptValidation.touched.batch_number &&
                  ReceiptValidation.errors.batch_number) ||
                error?.batch_number?.[0] ? (
                  <FormFeedback type="invalid">
                    {ReceiptValidation.errors.batch_number ||
                      error?.batch_number?.[0]}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Expire Date</Label>
                <Flatpickr
                  ref={exipreDateRef}
                  className="form-control d-block flatpickr-disabled"
                  placeholder="dd M,yyyy"
                  disabled={disableField({})}
                  options={{
                    altInput: true,
                    altFormat: "Y-m-d",
                    dateFormat: "Y-m-d",
                    defaultDate: ReceiptValidation.values.expire_date,
                  }}
                  onChange={value => {
                    ReceiptValidation.setFieldValue(
                      "expire_date",
                      moment(value[0]).format("YYYY-MM-DD")
                    )
                  }}
                />
                {(ReceiptValidation.touched.expire_date &&
                  ReceiptValidation.errors.expire_date) ||
                error?.expire_date ? (
                  <p className="text-danger" style={{ fontSize: "80%" }}>
                    {ReceiptValidation.errors.expire_date || error?.expire_date}
                  </p>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">
                  In Quantity (Quantity UNFPA)
                </Label>
                <Input
                  name="in_qty_per_unfpa"
                  placeholder=" In Quantity (Quantity UNFPA)"
                  type="number"
                  min={0}
                  onWheel={e => e.target.blur()}
                  disabled={disableField({})}
                  onChange={ReceiptValidation.handleChange}
                  onBlur={ReceiptValidation.handleBlur}
                  value={ReceiptValidation.values.in_qty_per_unfpa || ""}
                  onKeyUp={onUNFPAQuantityChange}
                  invalid={
                    (ReceiptValidation.touched.in_qty_per_unfpa &&
                      ReceiptValidation.errors.in_qty_per_unfpa) ||
                    error?.in_qty_per_unfpa?.[0]
                      ? true
                      : false
                  }
                />
                {(ReceiptValidation.touched.in_qty_per_unfpa &&
                  ReceiptValidation.errors.in_qty_per_unfpa) ||
                error?.in_qty_per_unfpa?.[0] ? (
                  <FormFeedback type="invalid">
                    {ReceiptValidation.errors.in_qty_per_unfpa ||
                      error?.in_qty_per_unfpa?.[0]}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Quantity (IP UOM)</Label>
                <Input
                  name="in_qty_per_ip"
                  placeholder=" Quantity (IP UOM)"
                  type="number"
                  min={0}
                  onWheel={e => e.target.blur()}
                  onChange={ReceiptValidation.handleChange}
                  onBlur={ReceiptValidation.handleBlur}
                  value={ReceiptValidation.values.in_qty_per_ip || ""}
                  invalid={
                    (ReceiptValidation.touched.in_qty_per_ip &&
                      ReceiptValidation.errors.in_qty_per_ip) ||
                    error?.in_qty_per_ip?.[0]
                      ? true
                      : false
                  }
                  readOnly={true}
                />
                {(ReceiptValidation.touched.in_qty_per_ip &&
                  ReceiptValidation.errors.in_qty_per_ip) ||
                error?.in_qty_per_ip?.[0] ? (
                  <FormFeedback type="invalid">
                    {ReceiptValidation.errors.in_qty_per_ip ||
                      error?.in_qty_per_ip?.[0]}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Lost Comment</Label>
                <Input
                  name="lost_comment"
                  placeholder="Comment"
                  type="text"
                  onChange={ReceiptValidation.handleChange}
                  onBlur={ReceiptValidation.handleBlur}
                  value={ReceiptValidation.values.lost_comment || ""}
                  onKeyUp={onUNFPAQuantityChange}
                  disabled={disableField({})}
                  invalid={
                    (ReceiptValidation.touched.lost_comment &&
                      ReceiptValidation.errors.lost_comment) ||
                    error?.lost_comment?.[0]
                      ? true
                      : false
                  }
                />
                {(ReceiptValidation.touched.lost_comment &&
                  ReceiptValidation.errors.lost_comment) ||
                error?.lost_comment?.[0] ? (
                  <FormFeedback type="invalid">
                    {ReceiptValidation.errors.lost_comment ||
                      error?.lost_comment?.[0]}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Lost Quantity</Label>
                <Input
                  name="lost_quantity"
                  placeholder="Lost Quantity"
                  type="number"
                  min={0}
                  onWheel={e => e.target.blur()}
                  onKeyUp={e => {
                    setLostQtyError(null)
                    if (checkForMinusNumber(e)) {
                      e.target.value = 0
                      return
                    }
                    const qty = ReceiptValidation.values.in_qty_per_ip
                    if (qty < e.target.value) {
                      e.target.value = 0
                      setLostQtyError("Lost Qty Should not greater then IP Qty")
                      return
                    }
                  }}
                  disabled={disableField({})}
                  onChange={ReceiptValidation.handleChange}
                  onBlur={ReceiptValidation.handleBlur}
                  value={ReceiptValidation.values.lost_quantity}
                  invalid={
                    (ReceiptValidation.touched.lost_quantity &&
                      ReceiptValidation.errors.lost_quantity) ||
                    error?.lost_quantity?.[0]
                      ? true
                      : false
                  }
                />
                {(ReceiptValidation.touched.lost_quantity &&
                  ReceiptValidation.errors.lost_quantity) ||
                error?.lost_quantity?.[0] ? (
                  <FormFeedback type="invalid">
                    {ReceiptValidation.errors.lost_quantity ||
                      error?.lost_quantity?.[0]}
                  </FormFeedback>
                ) : null}
                {lostQtyError && (
                  <p className="text-danger" style={{ fontSize: 12 }}>
                    {lostQtyError}
                  </p>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Total Value</Label>
                <Input
                  name="total_value"
                  placeholder="Total Value"
                  type="text"
                  onChange={ReceiptValidation.handleChange}
                  onBlur={ReceiptValidation.handleBlur}
                  value={ReceiptValidation.values.total_value || ""}
                  readOnly={true}
                  invalid={
                    (ReceiptValidation.touched.total_value &&
                      ReceiptValidation.errors.total_value) ||
                    error?.total_value?.[0]
                      ? true
                      : false
                  }
                />
                {(ReceiptValidation.touched.total_value &&
                  ReceiptValidation.errors.total_value) ||
                error?.total_value?.[0] ? (
                  <FormFeedback type="invalid">
                    {ReceiptValidation.errors.total_value ||
                      error?.total_value?.[0]}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Submission Date</Label>
                <Flatpickr
                  ref={submissionDateRef}
                  disabled={disableField({})}
                  className="form-control d-block"
                  placeholder="dd M,yyyy"
                  options={{
                    altInput: true,
                    altFormat: "Y-m-d",
                    dateFormat: "Y-m-d",
                    defaultDate: ReceiptValidation.values.date,
                    maxDate: moment().format("YYYY-MM-DD"),
                  }}
                  onChange={value => {
                    ReceiptValidation.setFieldValue(
                      "date",
                      moment(value[0]).format("YYYY-MM-DD")
                    )
                  }}
                />
                {(ReceiptValidation.touched.date &&
                  ReceiptValidation.errors.date) ||
                error?.date ? (
                  <p className="text-danger" style={{ fontSize: "80%" }}>
                    {ReceiptValidation.errors.date || error?.date}
                  </p>
                ) : null}
              </div>
            </Col>
          </Row>

          <div className="d-flex flex-wrap gap-2">
            <Button type="submit" color="primary" disabled={loading}>
              {loading
                ? "Please Wait..."
                : formMode === "Add"
                ? "Submit"
                : "Update"}
            </Button>
          </div>
        </Form>

        {formMode === "Add" && (
          <React.Fragment>
            <ReceiptsSubmitedList data={submited} setSubmited={SetSubmited} />
            <div className="d-flex flex-wrap gap-2">
              <Button
                type="button"
                onClick={saveAllHandler}
                color="primary"
                disabled={loading}
              >
                {loading
                  ? "Please Wait..."
                  : formMode === "Add"
                  ? "Save All"
                  : "Update"}
              </Button>
            </div>
          </React.Fragment>
        )}
      </CardBody>
    </Card>
  )
}

export default ProviderInForm
