import ProviderForm from "components/Provider/form"
import ProviderInForm from "components/ProviderIn/form"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  getProviderIps,
  getProviderItemCategories,
  getProviderProviders,
  getProviderProvinces,
  getProviderWarehouses,
} from "store/provider-dashboard/provider-dashboard-action"
import { storeProviderIn } from "store/provider-in/provider-in-action"
import { isObject } from "lodash"
import ProviderOutForm from "components/ProviderOut/form"
import {
  getProviderOutProviderAccounts,
  storeProviderOut,
} from "store/provider-out/provider-out-action"
import { providerOutAction } from "store/provider-out/provider-out-slice"

const AddProviderOut = () => {
  document.title = "Add Distribution"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => state.providerOut)

  const warehosues = useSelector(
    state => state.providerDashboard.providerWarehouses
  )
  const providers = useSelector(
    state => state.providerDashboard.providerProviders
  )

  const categories = useSelector(
    state => state.providerDashboard.ProviderItemCategoires
  )
  const ips = useSelector(state => state.providerDashboard.ProviderIps)
  const projects = useSelector(state => state.providerOut.projects)
  const providerAccounts = useSelector(
    state => state.providerOut.providerAccounts
  )
  const items = useSelector(state => state.providerIn.items)
  const provinces = useSelector(
    state => state.providerDashboard.providerProvinces
  )
  const facilities = useSelector(
    state => state.providerDashboard.providerFacilities
  )

  const receipts = useSelector(state => state.providerOut.receipts)

  const handleSubmit = (values, file) => {
    const document = new FormData()
    document.append("document", file)
    document.append("distributions", JSON.stringify(values))
    dispatch(storeProviderOut(document, navigate))
  }

  useEffect(() => {
    dispatch(getProviderWarehouses())
    dispatch(getProviderProviders())
    dispatch(getProviderItemCategories())
    dispatch(getProviderProvinces())
    dispatch(getProviderIps())
    dispatch(getProviderOutProviderAccounts())

    return () => {
      dispatch(providerOutAction.resetReceiptOut())
      dispatch(providerOutAction.resetProviderAccount())
    }
  }, [])

  return (
    <ProviderOutForm
      onSubmit={handleSubmit}
      loading={loading}
      error={error}
      formMode={"Add"}
      warehosues={warehosues?.data}
      providers={providers?.data}
      categories={categories?.data}
      items={items?.data}
      ips={ips?.data}
      provinces={provinces?.data}
      facilities={facilities?.data}
      receipts={receipts?.data}
      providerAccounts={providerAccounts?.data}
      projects={projects?.data}
    />
  )
}

export default AddProviderOut
